<template>
 <div class="bg-white rounded-md shadow-sm p-6 mb-4">
    <div>
        <section v-if="!respuestas">
          <div>
            <div class="flex items-center justify-between mb-4">
              <div>
                <p class="font-bold text-base">
                  Tiempo para dar respuesta
                </p>
              </div>
              <Button :label="`Ingresar respuesta ${data.tipo === 1 ? 'derecho de peticion' : data.tipo === 2 ? 'requerimiento' : 'PQR'}`" @click="$router.push({name:'pharmasan.administrativa.juridica.respuestas.crear', query: { base: route.params.id, tipo: 5, provisionalidad: false }})"></Button>
            </div>
            <div class="justify-between flex mt-4" :class="data.fechaVencimiento < data.fechaNotificacion ? 'text-red-600' : ''">
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.days }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.days }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Dias</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.hours }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.hours }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Horas</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.minutes }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.minutes }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Minutos</span>
                </div>
              </div>
              <div>
                <div class="text-center w-min">
                  <div class="flex" style="align-items: baseline;">
                    <p class="letra-cuenta-regresiva font-bold">{{ contador.seconds }}</p>
                    <span class="text-gray-400 font-medium">/{{ lapso.seconds }}</span>
                  </div>
                  <span class="text-gray-400 font-medium">Segundos</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-else>
          <p class="font-bold text-base">
            Respuesta del {{ data.tipo === 1 ? 'derecho de peticion' : data.tipo === 2 ? 'requerimiento' : 'PQR'}}
          </p>
          <div v-for="(respuesta, k) in data.respuestas" :key="k">
            <div v-if="!respuesta.provisionalidad">
              <div class="flex justify-between mt-4">
                <div>
                  <p>
                    Respondido por: <span class="font-medium">{{ respuesta.usuario[0].user_name }}</span>
                  </p>
                </div>
                <div>
                  <p>
                    Fecha: <span class="font-medium">{{$h.formatDate(respuesta.createdAt,'YYYY-MM-DD')}}</span>
                  </p>
                </div>
              </div>
              <p class="my-2">
                Observaciones: <span class="font-medium">{{ respuesta.observacion }}</span>
              </p>
              <div class="my-2" v-for="(archivo, j) in respuesta.paths" :key="j">
                <div class="flex justify-between">
                  <div>
                    <p>
                      {{ archivo.fileName }}
                    </p>
                  </div>
                  <div>
                    <button @click="verPdf(archivo.urlPath)">
                      <i class="pi pi-eye text-blue-600"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { onMounted, computed, ref, watch } from 'vue'
import PdfService from '../../../../../../../services/pdf.service'
import { useToast } from 'primevue/usetoast'
import derechosPeticionStore from '../../../../../../../store/derechosPeticion.store'
import dayjs from 'dayjs'

export default {
  name: 'soportesDerecho',
  setup () {
    const route = useRoute()
    // const validateResponse = ref(false)
    const data = computed(() => {
      return derechosPeticionStore.getters._derecho
    })
    const respuestas = computed(() => {
      return derechosPeticionStore.getters._derecho.respuestas.length
    })
    const textColor = ref(false)
    const validarColorContador = () => {
      if (`${data.value.fechaVencimiento}` < `${data.value.fechaNotificacion}`) {
        textColor.value = !textColor.value
      } else {
      }
    }
    // const textColor = computed(() => {
    //   return dayjs(`${data.value.fechaVencimiento}`) < dayjs(`${data.value.fechaNotificacion}`)
    // })
    const _PdfService = new PdfService()
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const toast = useToast()
    const contador = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })
    const lapso = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })
    const onUpload = () => {
      toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 })
    }
    setInterval(() => {
      const inicio = dayjs()
      const fin = dayjs(`${data.value.fechaVencimiento}`)
      const loquesea = dayjs.duration(fin - inicio)
      contador.value = (loquesea.$d)
    }, 1000)
    watch(data, (nuevo, viejo) => {
      if (nuevo) {
        lapso.value = dayjs.duration(dayjs(`${data.value.fechaVencimiento}`) - dayjs(`${data.value.fechaNotificacion}`)).$d
      }
    })
    onMounted(() => {
      validarColorContador()
    })
    return {
      data,
      verPdf,
      onUpload,
      textColor,
      contador,
      lapso,
      route,
      respuestas,
      validarColorContador
    }
  }
}
</script>
<style scoped>
::v-deep(.p-fileupload-buttonbar) {
  display: none;
}
::v-deep(.p-fileupload .p-fileupload-content ) {
  border: none;
}
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::v-deep(.p-button.p-fileupload-choose):hover {
  background-color: white;
  color: black;
  font-weight: 600;
}
::v-deep(.p-button.p-fileupload-choose):focus {
  box-shadow: none;
}
::v-deep(.p-button .p-button-icon-left) {
  display: none;
}
::v-deep(.p-button:enabled:hover .p-dialog .p-dialog-footer button) {
  width: 100% !important;
}
.letra-cuenta-regresiva {
  font-size: 2rem;
}

</style>
